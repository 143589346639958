<script>
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "index",
  components: {PageHeader},
  data() {
    return {
      order: null,
      selected_contract: null,

      month_options: [],

      form: {
        validity_month: '',
        plan: '',
        shipper_details: '',
        consignee_details: '',
        terminal_handling: '',
        agreed_rate: '',
        services: '',
        loading_date: '',
        laden_payer: '',
        empty_payer: '',
        departure_station: '',
        destination_station: '',
      }
    }
  },
  methods: {
    async getOrderDetails() {
      try {
        let response = await axios.get(`/wagon_order/list/${this.$route.params.id}/for_application/`)
        this.order = response.data
        if (this.order && this.order.company) {
          if ((this.order.company.contracts || []).length === 1) {
            this.selected_contract = this.order.company.contracts[0]
          }
        }
        this.form.departure_station = this.order.departure_station ? this.order.departure_station.name : ''
        this.form.destination_station = this.order.destination_station ? this.order.destination_station.name : ''
      } catch {
        alert("Error")
      }
    },
    formatDate(date) {
      return moment(date)
          .locale('ru')  // Set the locale to Russian
          .format('«DD» MMMM YYYY') + ' г'
    },
    getMonthName(monthNumber) {
      const safeMonthNumber = Math.max(1, Math.min(12, monthNumber));

      return moment()
          .month(safeMonthNumber - 1)  // Moment uses 0-based month index
          .locale('ru')
          .format('MMMM')
          .split('_').map(i => i.charAt(0).toUpperCase() + i.slice(1, i.length)).join(' ')
    },

    async submitForm() {
      try {
        await axios.post(`/wagon_order/for_application/${this.$route.params.id}/create/`, {
          period: this.getMonthName(this.form.validity_month),
          gu_number: this.form.plan,
          shipper_info: this.form.shipper_details,
          consignee_info: this.form.consignee_details,
          terminal_operation: this.form.terminal_handling,
          agreed_rate: this.form.agreed_rate,
          services: this.form.services,
          loading_date: this.form.loading_date,
          laden_payer: this.form.laden_payer,
          empty_payer: this.form.empty_payer,
          departure_station: this.form.departure_station,
          destination_station: this.form.destination_station,
          contract_id: this.selected_contract.id,
        })
        alert("success")
      } catch {
        alert("error")
      }
    }
  },
  computed: {
    items() {
      return [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Orders",
          href: "/orders/",
        },
        {
          text: "Conventional wagons",
          href: "/orders/conventional-wagons/",
        },
        {
          text: this.$route.params.id,
          href: "/orders/conventional-wagons/",
        },
        {
          text: "Create Application",
          active: true,
        },
      ]
    },
    productName() {
      if (this.order) {
        return this.order.product.name
      }
      return '-'
    },
    productCode() {
      if (this.order) {
        return this.order.product.etcng_code
      }
      return '-'
    },
    rollingStock() {
      if (this.order) {
        return this.order.rolling_stock
      }
      return '-'
    },
    shipper() {
      if (this.order) {
        return this.order.shipper
      }
      return '-'
    },
    consignee() {
      if (this.order) {
        return this.order.consignee
      }
      return '-'
    },
  },
  watch: {
    "$route.params.id": {
      handler(newValue) {
        if (newValue) {
          this.getOrderDetails()
        }
      },
      immediate: true
    }
  }
}
</script>

<template>
  <PageHeader :title="`Create Application - ${this.$route.params.id}`" :items="items"/>

  <b-card v-if="order && order.company">

    <b-row class="justify-content-center">
      <b-col style="min-width: min(992px, 100%); max-width: 992px; width: 100%;"
             sm="12" xl="12"
      >
        <b-row class="justify-content-center">
          <template v-for="contract in (order.company.contracts || [])" :key="`contract_${contract}`">
            <b-col sm="6" xl="3">
              <div
                  @click.stop="selected_contract = contract"
                  @mouseenter="contract.hovered = true"
                  @mouseleave="contract.hovered = false"
                  class="bg-light w-100 h-100 p-2 rounded-3 cursor-pointer border"
                  :class="{
                    'border-primary' : contract.hovered,
                    'border-light' : !contract.hovered,
                  }"
              >
                <div class="form-check">
                  <input v-model="selected_contract" class="form-check-input" type="radio"
                         name="flexRadioDefault" :value="contract"
                         :id="`contract${contract.id}`"
                  >
                  <label class="form-check-label" :for="`contract${contract.id}`">
                    {{ contract.name }}
                  </label>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-2 text-muted">
                  <span>{{ contract.start_date }}</span>
                  <span>-</span>
                  <span>{{ contract.expire_date }}</span>
                </div>
              </div>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <div class="d-flex flex-column align-items-center justify-content-center gap-4">
      <template v-if="selected_contract">

        <div class="text-black fw-medium"
             style="min-width: min(992px, 100%); max-width: 992px; width: 100%;">
          <div class="text-end mb-4">
            Приложение № 1 <br>
            к Договору № {{ selected_contract.name }} от {{ formatDate(selected_contract.start_date) }}.
          </div>
          <div class="text-center mb-4">
            Заявка № 1 <br>
            к Договору № {{ selected_contract.name }} от {{ formatDate(selected_contract.start_date) }}. <br>
            на транспортно-экспедиторские услуги
          </div>
          <div class="mb-4">
            В соответствии с Договором № IL-2023-015 от 01.01.2023 г. Клиент просит Исполнителя оказать услуги на
            следующих условиях:
          </div>

          <div>
            <table class="table table-nowrap table-bordered">
              <tbody>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">1</th>
                <td style="max-width: 120px;" class="text-truncate">Период действия заявки</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <select v-model="form.validity_month" class="form-select border-0 mb-0"
                          aria-label="Default select example"
                  >
                    <option selected disabled value="">Выберите период действия заявки</option>
                    <option v-for="i in 12" :key="`month${i}`" :value="i" class="text-capitalize">
                      {{ getMonthName(i) }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">2</th>
                <td style="max-width: 120px;" class="text-truncate">№ Плана (ГУ-12*)</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.plan" class="form-control border-0" placeholder="№ Плана (ГУ-12*)">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">3</th>
                <td style="max-width: 120px;" class="text-truncate">Наименование груза</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input disabled :value="productName" class="form-control border-0 bg-transparent"
                         placeholder="Код груза по ЕТСНГ">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">4</th>
                <td style="max-width: 120px;" class="text-truncate">Код груза по ЕТСНГ</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input disabled :value="productCode" class="form-control border-0 bg-transparent"
                         placeholder="Код груза по ЕТСНГ">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">5</th>
                <td style="max-width: 120px;" class="text-truncate">Род подвижного состава</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input disabled :value="rollingStock" class="form-control border-0 bg-transparent"
                         placeholder="Род подвижного состава">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">6</th>
                <td style="max-width: 120px;" class="text-truncate">Грузоотправитель, ж/д.код, ОКПО</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input disabled :value="shipper" class="form-control border-0 bg-transparent"
                         placeholder="Грузоотправитель, ж/д.код, ОКПО">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">7</th>
                <td style="max-width: 120px;" class="text-truncate">Почтовый адрес, телефон грузоотправителя</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.shipper_details" class="form-control border-0 bg-transparent"
                         placeholder="Почтовый адрес, телефон грузоотправителя">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">8</th>
                <td style="max-width: 120px;" class="text-truncate">Грузополучатель, ж/д код, ОКПО</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input disabled :value="consignee" class="form-control border-0 bg-transparent"
                         placeholder="Грузополучатель, ж/д код, ОКПО">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">9</th>
                <td style="max-width: 120px;" class="text-truncate">Почтовый адрес, телефон грузополучателя</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.consignee_details" class="form-control border-0 bg-transparent"
                         placeholder="Почтовый адрес, телефон грузополучателя">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">10</th>
                <td style="max-width: 120px;" class="text-truncate">Терминальная обработка (погрузка-выгрузка, <br>
                  перегруз/перетарка)
                </td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.terminal_handling" class="form-control border-0 bg-transparent"
                         placeholder="Терминальная обработка (погрузка-выгрузка, перегруз/перетарка)">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">11</th>
                <td style="max-width: 120px;" class="text-truncate">Согласованная ставка Экспедитора за услуги, без НДС
                </td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.agreed_rate" class="form-control border-0 bg-transparent"
                         placeholder="Согласованная ставка Экспедитора за услуги, без НДС"
                         type="number" step="any"
                  >
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">12</th>
                <td style="max-width: 120px;" class="text-truncate">Перечень услуг, включенных в ставку</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.services" class="form-control border-0 bg-transparent"
                         placeholder="Перечень услуг, включенных в ставку">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">13</th>
                <td style="max-width: 120px;" class="text-truncate">График погрузки</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.loading_date" class="form-control border-0 bg-transparent"
                         placeholder="Перечень услуг, включенных в ставку">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">14</th>
                <td style="max-width: 120px;" class="text-truncate">Плательщик ж/д тарифа при груженном рейсе</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.laden_payer" class="form-control border-0 bg-transparent"
                         placeholder="Плательщик ж/д тарифа при груженном рейсе">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">15</th>
                <td style="max-width: 120px;" class="text-truncate">Плательщик ж/д тарифа при порожнем рейсе</td>
                <td style="max-width: 120px;" class="pb-0 px-1 pt-1">
                  <input v-model="form.empty_payer" class="form-control border-0 bg-transparent"
                         placeholder="Плательщик ж/д тарифа при порожнем рейсе">
                </td>
              </tr>
              <tr>
                <th style="max-width: 15px;" scope="row" class="text-center">16</th>
                <th style="max-width: 120px;" class="text-truncate">Примечание:</th>
                <td>Leslie Alexander</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="text-black fw-medium" style="min-width: min(992px, 100%); max-width: 992px; width: 100%;">
          <h5 class="mb-4">1. График подачи на сентябрь месяц </h5>
          <table class="table table-nowrap table-bordered">
            <tbody>
            <tr>
              <th style="max-width: 20px" scope="row">
                Станция отправления- назначения
              </th>
              <td></td>
              <th style="max-width: 15px" class="text-center">Итого:</th>
            </tr>
            <tr class="align-middle">
              <th style="max-width: 20px" scope="row" class="p-0">
                <div>
                  <input v-model="form.departure_station" class="form-control border-0"
                         placeholder="Станция отправления">
                </div>
              </th>
              <td></td>
              <td class="text-center" style="max-width: 15px" rowspan="3">
                {{ order.quantity }}
              </td>
            </tr>
            <tr>
              <th style="max-width: 20px" scope="row" class="p-0">
                <div>
                  <input v-model="form.destination_station" class="form-control border-0" placeholder="Станция назначения">
                </div>
              </th>
            </tr>
            <tr>
              <th style="max-width: 20px" scope="row" class="p-0">
              </th>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-end" style="min-width: min(992px, 100%); max-width: 992px; width: 100%;">
          <img src="@/assets/images/wagon_application_stamp.png" alt="Wagon application stamp">
        </div>

        <div class="text-end" style="min-width: min(992px, 100%); max-width: 992px; width: 100%;">
          <b-button @click="submitForm()" size="lg"
                    variant="primary" class="w-100">Submit & Create
          </b-button>
        </div>
      </template>
    </div>
  </b-card>
</template>

<style scoped>

</style>